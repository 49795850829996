*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// .app {
//   *,
//   *::after,
//   *::before {
//     font-family: mon-regular;
//   }
// }
