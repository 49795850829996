.near-xxl-show {
  @media only screen and (max-width: $near-xxl) {
    display: none;
  }
}

.near-xxl-not-show {
  display: none;
  @media only screen and (max-width: $near-xxl) {
    display: block;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.u-w-60 {
  width: 60% !important;
}

.h-auto {
  height: auto;
}

.u-mt-4 {
  margin-top: 4rem !important;
}

.u-mt-2 {
  margin-top: 2rem !important;
}

.u-ml-7 {
  margin-left: 7rem !important;
}

.u-border-bottom {
  border-bottom: 1px solid;
}

.u-no-text-transform {
  text-transform: unset !important;
}
