body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(213, 213, 220, 0.22);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.styled-table__actions .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 0 !important;
}

.styled-table__actions
  .ant-dropdown-menu
  .ant-dropdown-menu-item
  .styled-table__action-item {
  margin: 0;
  padding: 5px 12px !important;
}

.color-error {
  color: #ff4d4f;
}

.flat-select .ant-select-selector {
  padding-left: 0 !important;
}

.ant-table-row:has(.disabled-selection-row) .ant-table-cell {
  color: rgba(0, 0, 0, 0.45) !important;
}

.ant-table-placeholder {
  z-index: 1 !important;
}

.pop-confirm-menu li {
  padding: 0 !important;
}

.pop-confirm-menu span {
  padding: 5px 12px;
}

.delete-album-file-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
}

.album-asset-progress {
  position: absolute;
  bottom: -8px;
  left: 8px;
}
