/* COLORS */

$color-grey: #f1f1f5;
$color-red: #c1112f;
$color-error: #ff4d4f;
$color-red-light: #da271c;
$color-submenu-active: #c1112f;
$color-menu-item-active: #da271c;
$color-white: #ffffff;
$color-text-black: #171725;
$color-black-1: #44444f;
$color-black-2: #696974;
$color-black-3: #13131a;
$color-black-4: #333333;
$color-grey-light: #fafafb;
$color-grey-light-2: #f6f6fc;
$color-grey-1: #d5d5dc;
$color-grey-2: #e2e2ea;
$color-grey-3: #717480;
$color-grey-4: #92929d;
$color-grey-5: #d8d8d8;
$color-grey-6: #707070;
$color-grey-7: #b2b2b2;
$color-grey-8: #f6f6fc;
$color-grey-9: #e5e5e8;
$color-grey-10: #f1f1f5;
$color-grey-11: #b5b5be;
$color-grey-12: #e5e5e5;
$color-black-light: #44444f;
$color-yellow-light: #ffc542;
$color-pink: #fc5a5a;
$color-green: #82c43c;
$color-green-2: #3dd598;
$color-orange: #ff974a;
$color-orange-2: #eccfbf;
$color-btn-orange: #ff4800;
$color-border-input: #e2e2ea;
$color-danger: #da271c;
$color-text-err: #f55735;

$color-checkbox: #ff4800;
$color-radio: #ff4800;
$color-box-shadow-light: #d2d7e0;
$color-box-shadow: #00000029;
$color-blue: #50b5ff;
$color-green-1: #3dd598;
$color-puple: #a461d8;
$background-grey-light: rgba(213, 213, 220, 0.22);
$background-grey-medium: rgba(213, 213, 220, 0.5);
$border-grey-light: rgba(146, 146, 157, 0.22);
$color-process-bar-red: #ff564a;

$color-tag-green: #3dd598;
$color-pink-deep: #ff9ad5;

/* FONT-FAMILY */
@font-face {
  font-family: mt-bold;
  src: url('../../../../../public/fonts/Montserrat/Montserrat-Bold.ttf');
}

@font-face {
  font-family: pop-regular;
  src: url('../../../../../public/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: pop-medium;
  src: url('../../../../../public/fonts/poppins/Poppins-Medium.ttf');
}

@font-face {
  font-family: pop-semibold;
  src: url('../../../../../public/fonts/poppins/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: ro-bold;
  src: url('../../../../../public/fonts/Roboto/Roboto-Bold.ttf');
}

@font-face {
  font-family: ro-medium;
  src: url('../../../../../public/fonts/Roboto/Roboto-Medium.ttf');
}

@font-face {
  font-family: ro-regular;
  src: url('../../../../../public/fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: ro-light;
  src: url('../../../../../public/fonts/Roboto/Roboto-Light.ttf');
}

@font-face {
  font-family: ro-black;
  src: url('../../../../../public/fonts/Roboto/Roboto-Black.ttf');
}

@font-face {
  font-family: mon-bold;
  src: url('../../../../../public/fonts/Montserrat/Montserrat-Bold.ttf');
}

@font-face {
  font-family: mon-semi-bold;
  src: url('../../../../../public/fonts/Montserrat/Montserrat-SemiBold.ttf');
}
@font-face {
  font-family: mon-regular;
  src: url('../../../../../public/fonts/Montserrat/Montserrat-Regular.ttf');
}
@font-face {
  font-family: mon-medium;
  src: url('../../../../../public/fonts/Montserrat/Montserrat-Medium.ttf');
}

/* BREAK POINT */
$default: 1920px;
$xxl: 1600px;
$near-xxl: 1400px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

$h-sm: 600px;
$h-md: 800px;
